import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "div" ]

  hide () {
    this.divTarget.classList.add('hidden');
  }

  
}
