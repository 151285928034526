// je créé une fonction qui va changer la classe appliquée aux étoiles
const toggleColorStars = (stars, rating) => {
		
	let z_1 = ['0.25','1.25','2.25','3.25','4.25'];
	let z_2 = ['0.5','1.5','2.5','3.5','4.5'];
	let z_3 = ['0.75','1.75','2.75','3.75','4.75'];
	let z_4 = ['1','2','3','4','5'];
	
	stars.forEach((star) => {
		
		if (star.id <= rating) {
			if (z_1.indexOf(star.id) >= 0) {star.className = "tooltip review-rating user-rate-25-f"}
			if (z_2.indexOf(star.id) >= 0) {star.className = "tooltip review-rating user-rate-50-f"}
			if (z_3.indexOf(star.id) >= 0) {star.className = "tooltip review-rating user-rate-75-f"}
			if (z_4.indexOf(star.id) >= 0) {star.className = "tooltip review-rating user-rate-100-f"}
		} else {
			if (z_1.indexOf(star.id) >= 0) {star.className = "tooltip review-rating user-rate-25"}
			if (z_2.indexOf(star.id) >= 0) {star.className = "tooltip review-rating user-rate-50"}
			if (z_3.indexOf(star.id) >= 0) {star.className = "tooltip review-rating user-rate-75"}
			if (z_4.indexOf(star.id) >= 0) {star.className = "tooltip review-rating user-rate-100"} 
		}
	});
};

// je créé une fonction qui va récupérer la valeur du rating
const updateRatingInputForm = (rating, formInput) => {
  //const formInput = document.querySelector('#hidden_rate');
  formInput.value = rating
 }

// je créé une fonction qui va appeler les deux premières fonctions
const dynamicRating = () => {

	var elementExists = document.getElementById("rate-el");
	
	if (elementExists === null) {
		return
	}

  // je récupère toutes les étoiles
  const stars = document.querySelectorAll('.review-rating');
  const starsReview = document.querySelector('#review-star-ratings'); 
  const formInput = document.querySelector('#hidden_rate');
  
  // valeur init
    toggleColorStars(stars, formInput.value);

  if ( stars.length > 0) {
    stars.forEach((star) => {
		
      // au clic je récupère la valeur du rating, j'applique le style css et j'ajoute une classe "selected" sur l'étoile
      star.addEventListener("click", (event) => {
		  
        const rating = event.currentTarget.id
        updateRatingInputForm(rating, formInput);
        toggleColorStars(stars, rating);
        star.classList.add("selected");
		
		document.getElementById("send_rate").click(); 

		
      });
	  
	  // souris dessus
      star.addEventListener("mouseover", (event) => {		
        // s'il n'y a pas de classe "selected" j'applique du style au passage de la souris
        const rating = event.currentTarget.id
        //if (!(document.querySelector(".selected"))) {
          toggleColorStars(stars, rating);
        //}
      });
    });
	
	// souris out
     starsReview.addEventListener("mouseout", (event) => {
		 
		// si aucune note est choisie --> tout à valeur initiale
		if (!(document.querySelector(".selected"))) { 
			toggleColorStars(stars, formInput.value);
		}
    });
  };
};

export { dynamicRating };

/*
 document.addEventListener('turbo:load', () => {
  // [...]
  dynamicRating();
});
*/

// window.addEventListener('DOMContentLoaded', dynamicRating); 
 window.addEventListener('turbo:load', dynamicRating); 
 


 