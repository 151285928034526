import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "target"];

  connect() {
    const sourceInputId = this.data.get("sourceId");
    const targetInputId = this.data.get("targetId");

    // Get the source and target input elements
    const sourceInput = document.getElementById(sourceInputId);
    const targetInput = document.getElementById(targetInputId);

    // Attach an input event listener to the source input
    sourceInput.addEventListener("input", () => {
      // Copy the value from source to target
      targetInput.value = sourceInput.value;

      // target form
      const targetForm = document.getElementById(this.data.get("formId"));

      if(targetForm) {
        targetForm.requestSubmit();
      }
    });

  }
}