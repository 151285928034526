import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
   addText(event) {
    const clickedText = event.target.textContent.trim();
    const inputId = event.target.dataset.inputId;
    const textInput = document.getElementById(inputId);
    const currentInputValue = textInput.value.trim();

    if (currentInputValue.length > 0) {
      textInput.value = `${currentInputValue} ${clickedText}`;
    } else {
      textInput.value = clickedText;
    }
  }
}