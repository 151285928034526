import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["div"]

  removeel(event) {

    this.divTargets.forEach((el) => {
      if (el.getAttribute("data-remove-key") == event.params.key) {
        el.remove()
      }
    });

  }

}
