import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "div" ]

  up() {

    const current_id = this.divTarget.dataset.wlId;

    // data id supérieur
    const target_id = parseInt(this.divTarget.dataset.wlId) - 1;

    // élément supérieur
    const target_div = document.querySelector(`[data-wl-id="${target_id}"]`);

    // mettre à jour la position si elle est affichée
    if (this.divTarget.dataset.wlDisplay == "true") {
      var position_moved = parseInt(document.getElementById(`position-${current_id}`).innerHTML);
      var position_adjacent = parseInt(document.getElementById(`position-${target_id}`).innerHTML);

      // si compte inveré
      if (this.divTarget.dataset.wlDisplayed == "true") {
        position_moved = position_moved + 1;
        position_adjacent = position_adjacent - 1;
      }
      else {
        position_moved = position_moved - 1;
        position_adjacent = position_adjacent + 1;
      }

      // mise à jour position élément déplacé
      document.getElementById(`position-${current_id}`).innerHTML = position_moved;
      document.getElementById(`position-${target_id}`).innerHTML = position_adjacent;

      // mise à jour des id
      document.getElementById(`position-${current_id}`).id = 'position-' + (target_id);
      document.getElementById(`position-${target_id}`).id = 'position-' + (current_id);
    }

    if ( target_div ) {
      // update div id
      this.divTarget.dataset.wlId = target_id

      // inséré avant élément supérieur
      target_div.insertAdjacentElement('beforebegin', this.divTarget);

      // update target_div id
      target_div.dataset.wlId = target_id + 1
    }

  }

  down () {

    const current_id = this.divTarget.dataset.wlId;

    // data id inférieur
    const target_id = parseInt(this.divTarget.dataset.wlId) + 1;

    // élément inférieur
    const target_div = document.querySelector(`[data-wl-id="${target_id}"]`)

    // mettre à jour la position si elle est affichée
    if (this.divTarget.dataset.wlDisplay == "true") {
      var position_moved = parseInt(document.getElementById(`position-${current_id}`).innerHTML);
      var position_adjacent = parseInt(document.getElementById(`position-${target_id}`).innerHTML);

      // si compte inveré
      if (this.divTarget.dataset.wlDisplayed == "true") {
        position_moved = position_moved - 1;
        position_adjacent = position_adjacent + 1;
      }
      else {
        position_moved = position_moved + 1;
        position_adjacent = position_adjacent - 1;
      }

      // mise à jour position élément déplacé
      document.getElementById(`position-${target_id}`).innerHTML = position_adjacent;
      document.getElementById(`position-${current_id}`).innerHTML = position_moved;

      // mise à jour des id
      document.getElementById(`position-${target_id}`).id = 'position-' + (current_id);
      document.getElementById(`position-${current_id}`).id = 'position-' + (target_id);
    }

    if ( target_div ) {
      // update div id
      this.divTarget.dataset.wlId = target_id

      // inséré avant élément inférieur
      target_div.insertAdjacentElement('afterend', this.divTarget);

      // update target_div id
      target_div.dataset.wlId = target_id - 1
    }
    else if ( this.divTarget.dataset.wlNxt == 'true' ) {
      // si il y a une page suivante et que c'est le dernier élément de cette page, on le masque
      this.divTarget.classList.add('hidden');
    }
  }

  hide () {
    this.divTarget.classList.add('hidden');
  }

}
