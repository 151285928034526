const checkbox = document.querySelector("#checkbox");
const html = document.querySelector("html");

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  document.documentElement.classList.add('dark')
  checkbox.checked = true
} else {
  document.documentElement.classList.remove('dark')
  checkbox.checked = false
}
