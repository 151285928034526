import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  cm_event() {
    // source input
    const sourceInput = document.getElementById("decoder-source-cm");

    // targets
    const targetAttendance = document.getElementById("event_attendance");
    const targetVenue = document.getElementById("query");
    const targetDate = document.getElementById("event_eventDate");
    const targetTapedDate = document.getElementById("event_tapeddate");
    const targetName = document.getElementById("event_name");

    // source value
    const inputValue = sourceInput.value;

    // Initialize variables
    let eventName = "";
    let day, month, year;
    let place = "";
    let day_bc, month_bc, year_bc;
    let live, attendance;
    let dateString, date_bcString;

    // Split the input into rows
    let rows = inputValue.split('\n');

    // Process each row
    rows.forEach(row => {

        // Check for "Name of the event:"
        if (row.startsWith("Name of the event:")) {
            eventName = row.replace("Name of the event:", "").trim();
        }

        // Check for "Date:"
        else if (row.startsWith("Date:")) {
            let datePart = row.replace("Date:", "").trim();
            [day, month, year] = datePart.split('.').map(part => parseInt(part));

            // Create a date string in YYYY-MM-DD format
            dateString = `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            live = true;
        }

        // Check for "Arena:"
        else if (row.startsWith("Arena:")) {
            place = row.replace("Arena:", "").trim();
        }

        // Check for "Attendance:"
        else if (row.startsWith("Attendance:")) {
            attendance = row.replace("Attendance:", "").trim();
        }

        // Check for "Broadcast date:"
        else if (row.startsWith("Broadcast date:")) {
            let broadcastDatePart = row.replace("Broadcast date:", "").trim();
            [day_bc, month_bc, year_bc] = broadcastDatePart.split('.').map(part => parseInt(part));

            // Create a date string in YYYY-MM-DD format
            date_bcString = `${year_bc.toString()}-${month_bc.toString().padStart(2, '0')}-${day_bc.toString().padStart(2, '0')}`;

            live = false;
        }
    });

    // live or broadcast
    if (live == false && date_bcString != dateString) {
      targetDate.value = date_bcString;
      targetTapedDate.value = dateString;
    }
    else {
      targetDate.value = dateString;
    }

    // Fill the target input fields with the extracted values
    targetVenue.value = place
    targetAttendance.value = attendance;
    targetName.value = eventName;
  }

  // quand copie, on alimente le reste
  jp_event() {

    // source input
    const sourceInput = document.getElementById("decoder-source");

    // targets
    const targetAttendance = document.getElementById("event_attendance");
    const targetVenue = document.getElementById("query");
    const targetDate = document.getElementById("event_eventDate");

    // source value
    const inputValue = sourceInput.value;

    // regex to split
    const regexBeforeMonth = /(\d+)月/;
    const regexBetweenMonthAndDay = /月(\d+)日/;
    const regexBetweenDotAndParentheses = /・([^（]+)（/;
    const regexBetweenKanshuAndNin = /観衆(\d+)人/;

    // Extract the values using regular expressions
    const matchesBeforeMonth = inputValue.match(regexBeforeMonth);
    const matchesBetweenMonthAndDay = inputValue.match(regexBetweenMonthAndDay);
    const matchesBetweenDotAndParentheses = inputValue.match(regexBetweenDotAndParentheses);
    const matchesBetweenKanshuAndNin = inputValue.match(regexBetweenKanshuAndNin);

    // Extracted values for date
    const month = matchesBeforeMonth ? parseInt(matchesBeforeMonth[1]) : 1;
    const day = matchesBetweenMonthAndDay ? parseInt(matchesBetweenMonthAndDay[1]) : 1;

    // Get the current year
    const currentYear = new Date().getFullYear();

    // Create a date string in YYYY-MM-DD format
    const dateString = `${currentYear}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    // Fill the target input fields with the extracted values
    targetDate.value = dateString;
    targetVenue.value = matchesBetweenDotAndParentheses ? matchesBetweenDotAndParentheses[1] : '';
    targetAttendance.value = matchesBetweenKanshuAndNin ? matchesBetweenKanshuAndNin[1] : '';
  }

  njpw_event() {
    // source input
    const sourceInput = document.getElementById("decoder-source-njpw");

    // targets
    const targetAttendance = document.getElementById("event_attendance");
    const targetVenue = document.getElementById("query");
    const targetDate = document.getElementById("event_eventDate");

    // source value
    const inputValue = sourceInput.value;

    // Extract year, month, and day
    const dateMatch = inputValue.match(/(\d{4})年(\d{1,2})月(\d{1,2})日/);
    const year = parseInt(dateMatch[1], 10);
    const month = parseInt(dateMatch[2], 10);
    const day = parseInt(dateMatch[3], 10);

    // Create a date string in YYYY-MM-DD format
    const dateString = `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    // Extract place
    const placeMatchWithParentheses = inputValue.match(/・(.*?)（/);
    const placeMatchWithoutParentheses = inputValue.match(/・(.*?)\s放送/);

    let place = null;

    if (placeMatchWithParentheses) {
      place = placeMatchWithParentheses[1].trim();
    } else if (placeMatchWithoutParentheses) {
      place = placeMatchWithoutParentheses[1].trim();
    }

    // Extract attendance
    const attendanceMatch = inputValue.match(/([\d,]+)人/);
    const attendance = attendanceMatch ? parseInt(attendanceMatch[1].replace(/,/g, ''), 10) : null;

    // Fill the target input fields with the extracted values
    targetDate.value = dateString;
    targetVenue.value = place
    targetAttendance.value = attendance;

  }

  dragongate_event() {
    // source input
    const sourceInput = document.getElementById("decoder-source-dragongate");

    // targets
    const targetAttendance = document.getElementById("event_attendance");
    const targetVenue = document.getElementById("query");
    const targetDate = document.getElementById("event_eventDate");

    // source value
    const inputValue = sourceInput.value;

    // regex to split
    const regex = /^(\d{4})\.(\d{2})\.(\d{2})　([^・]+)・([^　]+)　観衆：(\d+)人$/;

    // Use the exec method to extract information based on the regex pattern
    const match = regex.exec(inputValue);

    if (match) {
      // Extracted values
      const year = parseInt(match[1], 10);
      const month = parseInt(match[2], 10);
      const day = parseInt(match[3], 10);
      const place = match[5];
      const attendance = parseInt(match[6], 10);

      // Create a date string in YYYY-MM-DD format
      const dateString = `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

      // Fill the target input fields with the extracted values
      targetDate.value = dateString;
      targetVenue.value = place
      targetAttendance.value = attendance;
    }

  }

  bjw_event(){
    this.basic_japan_event(document.getElementById("decoder-source-bjw").value)
  }

  ddt_event(){
    this.basic_japan_event(document.getElementById("decoder-source-ddt").value)
  }

  michinoku_event(){
    const inputValue = this.replaceFullWidthDigits(document.getElementById("decoder-source-michinoku").value);

    this.basic_japan_event(inputValue);
  }

  noah_event() {
    this.basic_japan_event(document.getElementById("decoder-source-noah").value)
  }

  jto_event() {
    this.basic_japan_event(document.getElementById("decoder-source-jto").value)
  }

  iceribbon_event(){
    const inputValue = document.getElementById("decoder-source-iceribbon").value;
    this.basic_japan_event(inputValue);

    // place prefill for ice ribbon
    const placeRegex = /\(土\)(.*?) /;
    const placeMatch = inputValue.match(placeRegex);
    const place = placeMatch ? placeMatch[1].trim() : null;
    const targetVenue = document.getElementById("query");
    targetVenue.value = place
  }

  awg_event(){

    const inputValue = document.getElementById("decoder-source-awg").value;

    // targets
    const targetAttendance = document.getElementById("event_attendance");
    const targetVenue = document.getElementById("query");
    const targetDate = document.getElementById("event_eventDate");

    // Define the regex pattern
    const regexPattern = /^(\d+)\.(\d+)\.(\d+).*?[＠@]([^ ]+)/;

    // Use regex to match and capture the elements
    const match = inputValue.match(regexPattern);

    if (match) {
      const year = match[1];
      const month = match[2];
      const day = match[3];
      const place = match[4];

      // Create a date string in YYYY-MM-DD format
      const dateString = `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

      // Fill the target input fields with the extracted values
      targetDate.value = dateString;
      targetVenue.value = place
      targetAttendance.value = attendance;

    } else {
      console.log("Input value does not match the expected pattern.");
    }
  }

  basic_japan_event(inputValue) {

    // targets
    const targetAttendance = document.getElementById("event_attendance");
    const targetVenue = document.getElementById("query");
    const targetDate = document.getElementById("event_eventDate");

    // Regular expressions to capture the required elements
    const yearRegex = /(\d+)年/;
    const monthRegex = /(\d+)月/;
    const dayRegex = /(\d+)日/;
    const placeRegex = /・(.*?)観衆/;
    const attendanceRegex = /(\d+)人/;

    // Extracting values using the match method
    const yearMatch = inputValue.match(yearRegex);
    const monthMatch = inputValue.match(monthRegex);
    const dayMatch = inputValue.match(dayRegex);
    const placeMatch = inputValue.match(placeRegex);
    const attendanceMatch = inputValue.match(attendanceRegex);

    // Extracted values
    const year = yearMatch ? parseInt(yearMatch[1], 10) : null;
    const month = monthMatch ? parseInt(monthMatch[1], 10) : null;
    const day = dayMatch ? parseInt(dayMatch[1], 10) : null;
    const place = placeMatch ? placeMatch[1].trim() : null;
    const attendance = attendanceMatch ? parseInt(attendanceMatch[1].replace(/,/g, ''), 10) : null;

    // Create a date string in YYYY-MM-DD format
    const dateString = `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    // Fill the target input fields with the extracted values
    targetDate.value = dateString;
    targetVenue.value = place
    targetAttendance.value = attendance;

  }

  replaceFullWidthDigits(text) {
    // Use a regular expression to match full-width digits
    const fullWidthDigitsRegex = /[０-９]/g;

    // Replace full-width digits with normal digits
    const convertedText = text.replace(fullWidthDigitsRegex, function(match) {
      // Convert the full-width digit to its ASCII counterpart
      return String.fromCharCode(match.charCodeAt(0) - 65248);
    });

    return convertedText;
  }
}