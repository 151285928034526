import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
   static targets = ["userType", "contactType"];

  changeInput(event) {
    const clickedDiv = event.target;
    const userTypeValue = clickedDiv.getAttribute("data-user-type");
    const contactTypeValue = clickedDiv.getAttribute("data-contact-type");

    const inputTarget = clickedDiv.getAttribute("data-input");
    const inputValue = clickedDiv.getAttribute("data-inputv");

    if (inputTarget == 'contact_user_type'){
      this.userTypeTarget.value = inputValue;
      document.getElementById("contact_p1").classList.add("hidden");
      document.getElementById("contact_p2").classList.remove("hidden");
    }
    else if(inputTarget == 'contact_contact_type'){
      this.contactTypeTarget.value = inputValue;
      document.getElementById("contact_p2").classList.add("hidden");
      document.getElementById("contact_p3").classList.remove("hidden");
    }

  }
}