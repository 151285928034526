import { Controller } from "@hotwired/stimulus";
import L from "leaflet";
import { assetPaths } from "../config/asset_paths";
//import 'leaflet/dist/leaflet.css'; // Importer le CSS de Leaflet

export default class extends Controller {
  static values = { lat: Number, lng: Number, zoom: Number }

  connect() {
    this.initializeMap();
    this.element.parentElement.hidden = true
  }

  initializeMap() {
    const customIcon = L.icon({
      iconUrl: assetPaths.customIcon, // URL de l'icône du marqueur
      iconSize: [25, 41],                 // Taille de l'icône [largeur, hauteur]
      iconAnchor: [12, 41],               // Point d'ancrage de l'icône
      popupAnchor: [1, -34],              // Position d'affichage du popup par rapport à l'icône
      shadowUrl: assetPaths.markerShadow, // URL de l'ombre de l'icône
      shadowSize: [41, 41],                // Taille de l'ombre [largeur, hauteur]
      zoom: 7
    });

    const map = L.map(this.element, {attributionControl: false}).setView([this.latValue, this.lngValue], this.zoomValue);

    // Ajouter le contrôle d'attribution sans le préfixe Leaflet
    L.control.attribution({
        position: 'bottomright', // Position peut être changée à 'topright', 'topleft', etc.
        prefix: false            // Supprimer le préfixe Leaflet
    }).addTo(map);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors',
      detectRetina: true,
      maxZoom: 19
    }).addTo(map);

    L.marker([this.latValue, this.lngValue], { icon: customIcon, draggable: false, autoPan: false })
    .addTo(map);
  }


}
