import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String
  }

  connect() {
     // Masquer le bouton si le navigateur ne supporte pas la Web Share API
     if (!navigator.share) {
       this.element.style.display = 'none';
     }
   }

  share(event) {
    event.preventDefault()

    const shareData = {
      title: this.titleValue,
      text: this.textValue,
      url: this.urlValue
    }
    
    navigator.share(shareData)
    //  .then(() => console.log('Partage réussi'))
    //    .catch((error) => console.error('Erreur de partage:', error))
  }
}
