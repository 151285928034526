// app/javascript/controllers/flatpickr_controller.js
import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["input"];
  flatpickrInstance = null;

  static values = {
    year: Number,
    month: Number,
    day: Number,
    form: String,
  };

  connect() {
    // Déterminer la locale basée sur l'attribut lang de la balise <html>
    const userLocale = document.documentElement.lang || 'default';

    // Récupérer les valeurs d'année, mois et jour
    const year = this.yearValue;
    const month = this.monthValue;
    const day = this.dayValue;

    // Déterminer les dates par défaut en fonction des valeurs disponibles
   let defaultDate;
   if (year && month && day) {
     // Date exacte
     defaultDate = new Date(year, month - 1, day); // `month - 1` car les mois en JS sont 0-indexés
   } else if (year && month) {
     // Intervalle d'un mois
     const startDate = new Date(year, month - 1, 1);
     const endDate = new Date(year, month, 0); // Dernier jour du mois
     defaultDate = [startDate, endDate];
   } else if (year) {
     // Intervalle d'une année
     const startDate = new Date(year, 0, 1);
     const endDate = new Date(year, 11, 31); // Dernier jour de l'année
     defaultDate = [startDate, endDate];
   }

    // Utiliser `flatpickr` globalement exposé par le CDN
    this.flatpickrInstance = window.flatpickr(this.inputTarget, {
      mode: "range",
      dateFormat: "Y-m-d",
      allowInput: false,
      inline: true,
      showMonths: 1,
      altInput: true,
      altFormat: "F j, Y",
      locale: userLocale || 'default',
      defaultDate: defaultDate,
      onChange: this.submitForm.bind(this)
    });

    document.querySelector('input.form-control.input').style.display = 'none';
  }

  // Méthode pour appliquer le preset sélectionné
 setPreset(event) {
   const preset = event.currentTarget.dataset.preset;
   const today = new Date();
   let startDate, endDate;

   switch (preset) {
     case "today":
       startDate = today;
       endDate = today;
       break;
     case "yesterday":
       startDate = new Date(today);
       startDate.setDate(today.getDate() - 1);
       endDate = startDate;
       break;
     case "last48h":
       startDate = new Date(today);
       startDate.setDate(today.getDate() - 2);
       endDate = today;
       break;
     case "lastWeek":
       startDate = new Date(today);
       startDate.setDate(today.getDate() - 7);
       endDate = today;
       break;
     case "lastMonth":
      startDate = new Date(today);
      startDate.setMonth(today.getMonth() - 1); // Un mois avant aujourd'hui
      startDate.setDate(today.getDate()); // Conserver le même jour du mois si possible
      endDate = today;
      break;
     default:
       startDate = today;
       endDate = today;
   }

   // Mettre à jour le range sélectionné dans Flatpickr
   this.flatpickrInstance.setDate([startDate, endDate], true); // Le deuxième paramètre 'true' force la mise à jour
 }

   // Fonction pour soumettre le formulaire
  submitForm(selectedDates, dateStr, instance) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._submit();
    }, 300);
  }

  _submit() {
    // Recherchez le formulaire associé à l'input cible
    const form = document.getElementById(this.formValue);
    if (form) {
      form.requestSubmit(); // Soumet le formulaire
    }
  }
}
