import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener("paste", (event) => {
      //event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("text/plain");

      // Parse the pasted data as needed
      const parsedData = this.parsePastedData(pastedData);

      // Trigger an action to send the parsed data to the server
      this.sendDataToServer(parsedData);
    });
  }

  parsePastedData(data) {
    const tableRows = data.split('\n');
    const parsedData = [];

    tableRows.forEach((row, rowIndex) => {
      const cells = row.split('\t'); // Split each row into cells
      const rowData = {
        row: rowIndex,
        cells: [],
      };

      cells.forEach((cell, cellIndex) => {
        const trimmedCell = cell.trim();
        if (trimmedCell !== '') {
          const cellData = {
            cell: cellIndex,
            content: trimmedCell, // Save the trimmed cell content
          };
          rowData.cells.push(cellData);
        }
      });

      // Push rowData into parsedData only if it has cells
      if (rowData.cells.length > 0) {
        parsedData.push(rowData);
      }
    });

    return JSON.stringify(parsedData);
  }


  sendDataToServer(parsedData) {
    // Trigger an action to send the parsed data to the server
    //this.element.dispatchEvent(new CustomEvent("data-parsed", { detail: parsedData }));
    console.log(JSON.stringify({ parsed_data: parsedData }));
    const csrfToken = document.querySelector("meta[name=csrf-token]").content;
      fetch("/generated_stardom", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        "Accept": "application/json", // Make sure this is included
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ parsed_data: parsedData }),
      })
      .then(response => response.json())
      .then(data => {
        // Handle the response from the server if needed
      })
      .catch(error => {
        console.error("Error:", error);
      });

  }


}
