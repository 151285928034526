import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["section"]

  connect() {
    this.index = 0;
    this.updateSections();  // Initialiser les sections au démarrage

    // Configurer un MutationObserver pour surveiller les changements dans le DOM
    this.mutationObserver = new MutationObserver(this.handleMutations.bind(this));
    this.mutationObserver.observe(this.element, {
      childList: true,
      subtree: true
    });
  }

  disconnect() {
    this.mutationObserver.disconnect();  // Arrêter d'observer quand le contrôleur est déconnecté
  }

  handleMutations(mutations) {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        //console.log("Mutation détectée, mise à jour des sections");
        this.updateSections();  // Réinitialiser les sections quand le DOM change
      }
    });
  }

  updateSections() {
    // Rechercher toutes les cibles actuelles
    this.sections = this.sectionTargets;
    //console.log("Sections mises à jour:", this.sections);

    // Réinitialiser l'observation pour chaque section
    if (this.observer) {
      this.observer.disconnect();
    }

    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    });

    this.sections.forEach(section => {
      this.observer.observe(section);
    });
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.index = this.sections.indexOf(entry.target);
        //console.log("Section visible:", entry.target, "Index:", this.index);
      }
    });
  }

  nextSection() {
    let nextIndex = (this.index + 1) % this.sections.length;
    //console.log("Défilement vers l'index:", nextIndex, "Section:", this.sections[nextIndex]);

    if (this.sections[nextIndex]) {

      const yOffset = -190;
      const element = this.sections[nextIndex];
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});

      //this.sections[nextIndex].scrollIntoView({ top: -300, behavior: 'smooth' });
    } else {
      //console.error("Section suivante non trouvée !");
    }
  }
}