import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['time']
  interval = null
  startTime = null

  start() {
    if (this.interval === null) {
      this.startTime = new Date()
      this.interval = setInterval(this.update.bind(this), 1000)
    }
  }

  stop() {
    if (this.interval !== null) {
      clearInterval(this.interval)
      this.interval = null
      this.startTime = null
    }
  }

  update() {
    const currentTime = new Date()
    const elapsedTime = (currentTime - this.startTime) / 1000

    const hours = Math.floor(elapsedTime / 3600)
    const minutes = Math.floor((elapsedTime % 3600) / 60)
    const seconds = Math.floor(elapsedTime % 60)

    const formattedTime = [hours, minutes, seconds]
      .map(time => String(time).padStart(2, '0'))
      .join(':')

    this.timeTarget.innerText = formattedTime
  }
}
