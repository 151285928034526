import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox'];

  toggleAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = !checkbox.checked;
    });
  }
}
