import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "source", "hideable" ]

  showTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = false
    });
  }

  hideTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = true
    });
  }

  toggleTargets(event) {
    this.hideableTargets.forEach((el) => {
      if (el.getAttribute("data-filter-key") == event.params.key) {
        el.hidden = !el.hidden
      }
      else {
        el.hidden = true
      }
    });
  }

  toggleSingleTarget(event) {
    this.hideableTargets.forEach((el) => {
      if (el.getAttribute("data-filter-key") == event.params.key) {
        el.hidden = !el.hidden
      }
      else {
        //el.hidden = true
      }
    });
  }

  toggleFinder() {
    const ffinder = document.getElementById("fullfinder_form")
    ffinder.hidden = !ffinder.hidden

    if (ffinder.hidden == false) {
      document.getElementById("ffinder_input").focus();
    }
  }
}