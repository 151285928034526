import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text"]

  connect() {
    this.originalText = this.textTarget.innerText;
  }

  changeText(event) {
    const newText = event.currentTarget.dataset.hoverText;
    this.textTarget.classList.remove('text-zinc-400')
    this.textTarget.classList.add('text-red-800')
    this.textTarget.innerText = newText
  }

  resetText() {
    this.textTarget.classList.remove('text-red-800')
    this.textTarget.classList.add('text-zinc-400')
    this.textTarget.innerText = this.originalText
  }
}