import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = { formattingOption: String }

    connect() {
      this.textarea = document.getElementById("bb-body");
    }

    replaceText(event) {
      const button = event.target;
      const value = button.dataset.value;
      const value_end = button.dataset.valueend;

      if (this.textarea.selectionStart !== undefined) {
        const start = this.textarea.selectionStart;
        const end = this.textarea.selectionEnd;
        const selectedText = this.textarea.value.substring(start, end);

        const textBeforeSelection = this.textarea.value.substring(0, start);
        const textAfterSelection = this.textarea.value.substring(end);

        this.textarea.value = textBeforeSelection + value + selectedText + value_end + textAfterSelection;

        // Update the textarea's value
        this.textarea.dispatchEvent(new Event('input'));
      }
    }

    copyText(event) {

      // fight id
      const targetFightId = document.getElementById("new_fight_id").innerHTML;

      const button = event.target;
      let target = button.dataset.targetid;
      const targetFormBaseId = button.dataset.targetform;
      const fightIdSeparatorNeeded = button.dataset.plusid;
      const fightIdSeparatorNeededForm = button.dataset.plusidform;

      // si il y a un text selectionné
      if (this.textarea.selectionStart !== undefined) {
        // sélection
        const start = this.textarea.selectionStart;
        const end = this.textarea.selectionEnd;
        const selectedText = this.textarea.value.substring(start, end);

        // text avant . après
        const textBeforeSelection = this.textarea.value.substring(0, start);
        const textAfterSelection = this.textarea.value.substring(end);

        // cible
        if(fightIdSeparatorNeeded == "true"){
          target = target + "-" + targetFightId;
        };

        const targetInput = document.getElementById(target);

        // on supprime le text du textare
        this.textarea.value = textBeforeSelection + textAfterSelection;

        // on affecte l'input cible
        targetInput.value = selectedText;

        targetInput.focus();
        //targetInput.requestSubmit();

        // Update the textarea's value
        this.textarea.dispatchEvent(new Event('input'));

        // on retravaille le form cible
        let formId = targetFormBaseId

        if(fightIdSeparatorNeededForm == "true"){
          formId = targetFormBaseId + "-" + targetFightId
        };

        const targetForm = document.getElementById(formId);

        // et on l'envoi
        targetForm.requestSubmit();
      }

    }

    copyTime(event){

      const button = event.target;
      let target = button.dataset.targetid;
      const targetFormBaseId = button.dataset.targetform;

      // si il y a un text selectionné
      if (this.textarea.selectionStart !== undefined) {
        // sélection
        const start = this.textarea.selectionStart;
        const end = this.textarea.selectionEnd;
        const selectedText = this.textarea.value.substring(start, end);

        // retraitement si caractères jap
        let convertedString = selectedText.replace(/[０-９：]/g, function(match) {
          return String.fromCharCode(match.charCodeAt(0) - 0xFEE0);
        });

        const convertedStringUpdated = convertedString.replace('分', ':');
        const timeComponents = convertedString.split(':');

        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        // Check the length of the timeComponents array to determine the format
        if (timeComponents.length === 2) {
          // Format: "mm:ss"
          minutes = parseInt(timeComponents[0], 10);
          seconds = parseInt(timeComponents[1], 10);
        } else if (timeComponents.length === 3) {
          // Format: "hh:mm:ss"
          hours = parseInt(timeComponents[0], 10);
          minutes = parseInt(timeComponents[1], 10);
          seconds = parseInt(timeComponents[2], 10);
        }

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        // text avant . après
        const textBeforeSelection = this.textarea.value.substring(0, start);
        const textAfterSelection = this.textarea.value.substring(end);

        // on supprime le text du textare
        this.textarea.value = textBeforeSelection + textAfterSelection;

        // on affecte l'input cible
        const targetForm = document.getElementById(targetFormBaseId);
        const targetH = targetForm.getElementsByClassName("time")[1];
        const targetM = targetForm.getElementsByClassName("time")[2];
        const targetS = targetForm.getElementsByClassName("time")[3];
        targetH.value = formattedHours;
        targetM.value = formattedMinutes;
        targetS.value = formattedSeconds;

        targetS.focus();

        // Update the textarea's value
        this.textarea.dispatchEvent(new Event('input'));

        // et on l'envoi
        targetForm.requestSubmit();
      }
    };

 }