import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "div" ]

  up() {

    const scope_value = this.divTarget.dataset.adjacentelbisScope
    const scope_current_id = `wlbisId${scope_value.charAt(0).toUpperCase()}${scope_value.slice(1)}`;

    // data id supérieur
    const target_id = parseInt(this.divTarget.dataset[scope_current_id]) - 1;

    // élément supérieur
    const target_div = document.querySelector(`[data-wlbis-id-${scope_value}="${target_id}"]`)

    if ( target_div ) {
      // update div id
      this.divTarget.dataset[scope_current_id] = target_id

      // inséré avant élément supérieur
      target_div.insertAdjacentElement('beforebegin', this.divTarget);

      // update target_div id
      target_div.dataset[scope_current_id] = target_id + 1
    }
  }

  down () {

    const scope_value = this.divTarget.dataset.adjacentelbisScope
    const scope_current_id = `wlbisId${scope_value.charAt(0).toUpperCase()}${scope_value.slice(1)}`;

    // data id inférieur
    const target_id = parseInt(this.divTarget.dataset[scope_current_id]) + 1;

    // élément inférieur
    const target_div = document.querySelector(`[data-wlbis-id-${scope_value}="${target_id}"]`)

    if ( target_div ) {
      // update div id
      this.divTarget.dataset[scope_current_id] = target_id

      // inséré avant élément inférieur
      target_div.insertAdjacentElement('afterend', this.divTarget);

      // update target_div id
      target_div.dataset[scope_current_id] = target_id - 1
    }
    else if ( this.divTarget.dataset.wlNxt == 'true' ) {
      // si il y a une page suivante et que c'est le dernier élément de cette page, on le masque
      this.divTarget.classList.add('hidden');
    }
  }

  hide () {
    this.divTarget.classList.add('hidden');
  }

}
