import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "specific", "auto", "source", "input"];

  connect() {
    if (this.hasAutoTarget) {
      this.submit();
    }
  }

  // submit only one specific form immediatly
  submitone({ params: { id }}) {
    document.getElementById(id).focus();
    document.getElementById(id).click();
  }

  // refocus to another input
  refocus({ params: { refocus } }) {

    document.getElementById(refocus).focus();
  }

  // refocus to another input with ctrl+
  refocusctrl({ params: { refocusctrl } }) {
    document.getElementById(refocusctrl).children[4].focus();
  }

  // refocus direct to another input with ctrl+
  refocusctrldirect({ params: { refocusctrl } }) {
    document.getElementById(refocusctrl).focus();
    this.timeout = setTimeout(() => {
      document.getElementById(refocusctrl).value = ''
    }, 100);
  }

  // refocus direct to another input with ctrl+
  refocusshifts({ params: { refocusshifts } }) {
    document.getElementById(refocusshifts).focus();
    this.timeout = setTimeout(() => {
      document.getElementById(refocusshifts).value = ''
    }, 100);
  }

  // toggle is winner input
  togglewinner({ params: { winner } }) {
    document.getElementById(winner).checked = !document.getElementById(winner).checked
  }

  submit() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._submit();
    }, 300);
  }

  _submit() {
    this.formTarget.requestSubmit();
  }

  submitprecisedtarget() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._submitprecisedtarget();
    }, 300);
  }

  _submitprecisedtarget() {
    document.getElementById(this.sourceTarget.dataset.submitFormPrecisedform).requestSubmit();
  }

  submit_id() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._submit_id();
    }, 300);
  }

  _submit_id() {
    // Récupère l'ID du formulaire depuis l'attribut data-form-id de l'input
    const formId = this.inputTarget.dataset.formId;

    if (formId) {
      // Sélectionne le formulaire avec l'ID correspondant
      const form = document.getElementById(formId);

      if (form) {
        // Soumet le formulaire
        form.requestSubmit();
      } else {
        console.warn(`Form with ID '${formId}' not found.`);
      }
    }
  }

  submit_id_second() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._submit_id_second();
    }, 300);
  }

  _submit_id_second() {
    // Récupère l'ID du formulaire depuis l'attribut data-form-id de l'input
    const formId = this.inputTarget.dataset.formIdSecond;

    if (formId) {
      // Sélectionne le formulaire avec l'ID correspondant
      const form = document.getElementById(formId);

      if (form) {
        // Soumet le formulaire
        form.requestSubmit();
      } else {
        console.warn(`Form with ID '${formId}' not found.`);
      }
    }
  }
}
