import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "country", "city", "state", "place" ]

  prefill () {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._prefill();
    }, 320);
}

  _prefill () {

    const result = document.querySelector(`[data-prefillvenue-id]`)

    if ( result ) {
      const src_country = document.querySelector(`[data-prefillvenue-country]`).textContent
      const src_state = document.querySelector(`[data-prefillvenue-state]`).textContent
      const src_city = document.querySelector(`[data-prefillvenue-city]`).textContent
      const src_place = document.querySelector(`[data-prefillvenue-place]`).textContent

      this.countryTarget.value = src_country
      this.stateTarget.value = src_state
      this.cityTarget.value = src_city
      this.placeTarget.value = src_place
    }

  }
 }