import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dlcheckbox'];

  decisionclick() {
    checkbox.checked = !checkbox.checked
    this.decision();
  }

  decision() {
    checkbox.checked
    //? html.classList.add("dark")
    //: html.classList.remove("dark");
    ? localStorage.theme = 'dark'
    : localStorage.theme = 'light'

    if (localStorage.theme === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

}
