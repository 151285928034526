import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  goUp() {
    // Scroll to the top smoothly
      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });
  }
}
