import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["source", "target"]

  copy(event) {
    const sourceField = event.target;
    const targetId = sourceField.getAttribute("data-target-id");
    const referenceValue = sourceField.getAttribute("data-copy-reference-value");
    const targetElement = document.getElementById(targetId)

    if (targetElement) {
      targetElement.value = referenceValue
    }

    // send form
    if (targetElement) {
      targetElement.form.requestSubmit()
    }
  }

}