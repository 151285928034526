import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
   static targets = [ "source", "filterable" ]

   filter(event) {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()

    //console.log(this.sourceTarget)

    this.filterableTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-filter-key")
    //  console.log(el)

      //console.log(el.parentNode)
      el.parentNode.parentNode.classList.toggle("filter--notFound", !filterableKey.includes( lowerCaseFilterTerm ) )
      el.classList.toggle("filter--notFound", !filterableKey.includes( lowerCaseFilterTerm ) )
    })
  }

  filterdirect(event) {
   let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()
   
   this.filterableTargets.forEach((el, i) => {
     let filterableKey =  el.getAttribute("data-filter-key")
     el.classList.toggle("filter--notFound", !filterableKey.includes( lowerCaseFilterTerm ) )
   })
 }
}
